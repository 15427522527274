import Icon from 'mycs/shared/components/Icon/Icon';
import StarRating from 'mycs/shared/components/ReviewsBlock/StarRating';
import ReviewReply from 'mycs/shared/components/ReviewsBlock/ReviewReply/ReviewReply';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { TrustPilotReview } from 'mycs/api/UserAPI';

import styles from './CustomerReview.scss';

function formatDate(dateString: string, locale: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  return locale.includes('de') ? formattedDate.replace('.', '') : formattedDate;
}

// CustomerReview component
export default function CustomerReview({
  review,
}: {
  review: TrustPilotReview;
}) {
  const { title, text, stars, isVerified, companyReply } = review;
  const name = review.consumer.displayName;
  const { locale } = useLocale();
  const localisation = locale.replace('_', '-');
  const date = formatDate(review.createdAt, localisation);

  const renderVerifiedBadge = (isVerified: boolean) => {
    if (!isVerified) return null;
    return (
      <div className={styles.verifiedBadge}>
        <Icon
          className={styles.verifiedBadgeIcon}
          iconName="customer-reviews/verified_buyer.svg"
          isInline
        />
        {I18nUtils.localize(locale, 'Verified buyer')}
      </div>
    );
  };

  return (
    <div className={styles.review}>
      <div className={styles.reviewInfo}>
        <div className={styles.customerName}>
          {name}
          {renderVerifiedBadge(isVerified)}
        </div>
        <div className={styles.starRating}>{<StarRating stars={stars} />}</div>
        <span className={styles.date}>{date}</span>
      </div>
      <div className={styles.reviewText}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>
        {companyReply && (
          <div className={styles.reviewReplyContainer}>
            <ReviewReply
              text={companyReply.text}
              date={formatDate(companyReply.createdAt, localisation)}
              locale={locale}
            />
          </div>
        )}
      </div>
    </div>
  );
}

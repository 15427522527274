import classNames from 'classnames';

import { CfBreadcrumbs, CfNavLink } from '@mycs/contentful';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import Icon from 'mycs/shared/components/Icon/Icon';

import styles from './BreadcrumbsLP.scss';

type BreadcrumbsLPProps = {
  content: CfBreadcrumbs;
  showLastLink?: boolean;
  target?: 'none' | '_self' | '_blank';
};

export default function BreadcrumbsLP({
  content,
  showLastLink = false,
  target,
}: BreadcrumbsLPProps) {
  const { links, staticLayout, hideOnMobile } = content;

  if (!links || !links.length) return null;

  return (
    <nav
      className={classNames(
        styles.container,
        {
          [styles.staticLayout]: staticLayout,
          [styles.hideOnMobile]: hideOnMobile,
        },
        'contentContainer'
      )}
      aria-label="Breadcrumb"
    >
      <ol
        className={styles.list}
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        {links.map((link, index) => (
          <BreadcrumbLink
            key={`${link.url}_${link.title}_${index}`}
            link={link}
            index={index}
            isLast={index + 1 >= links.length}
            showLastLink={showLastLink}
            target={target}
          />
        ))}
      </ol>
    </nav>
  );
}

function BreadcrumbLink({
  link,
  index,
  isLast,
  showLastLink,
  target,
}: {
  link: CfNavLink;
  index: number;
  isLast: boolean;
  showLastLink: boolean;
  target?: 'none' | '_self' | '_blank';
}) {
  const href = showLastLink || !isLast ? link.url : undefined;
  const { baseUrl } = useLocale();

  const itemId = link.url ? new URL(link.url, baseUrl).toString() : undefined;

  return (
    <li
      itemScope
      itemProp="itemListElement"
      itemType="http://schema.org/ListItem"
      className={styles.item}
    >
      <Alink
        href={href}
        target={target}
        itemScope
        itemType="http://schema.org/Thing"
        itemProp="item"
        itemID={itemId}
        text={link.url}
        aria-current={isLast ? 'page' : undefined}
      >
        <span className={href ? 'gtm-breadcrumbs' : ''} itemProp="name">
          {link.title}
        </span>
      </Alink>

      {isLast ? (
        ''
      ) : (
        <span className={styles.iconWrap}>
          <Icon
            className={styles.chevron}
            iconName="general/arrow-chevron-right"
          />
        </span>
      )}

      <meta itemProp="position" content={(index + 1).toString()} />
    </li>
  );
}

export function LegacyBreadcrumbs(
  props: Omit<CfBreadcrumbs, '_id' | '_contentType'> & {
    showLastLink?: boolean;
    target?: 'none' | '_self' | '_blank';
  }
) {
  return (
    <BreadcrumbsLP
      content={{
        ...props,
        _id: 'legacyBreadcrumbs',
        _contentType: 'wizardStep',
      }}
      showLastLink={props.showLastLink ?? false}
      target={props.target}
    />
  );
}

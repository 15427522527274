import { useEffect, useMemo } from 'react';

import { CfRibbon } from '@mycs/contentful';
import {
  getRibbonsFromReduxState,
  setRibbons,
} from 'mycs/shared/state/slices/ribbonsSlice';
import { useAppDispatch, useAppSelector } from 'mycs/shared/state/store';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Logger from 'mycs/shared/services/Logger';
import RibbonService from 'mycs/shared/services/RibbonService/RibbonService';

export function useRibbon(): CfRibbon | null {
  const { locale, countryCode } = useLocale();
  const dispatch = useAppDispatch();

  const rawRibbons = useAppSelector((state) =>
    getRibbonsFromReduxState(state, locale)
  );
  const ribbon = useMemo(
    () => RibbonService.getRibbon(rawRibbons),
    [rawRibbons]
  );

  useEffect(() => {
    if (!locale) {
      return;
    }

    RibbonService.loadRibbons(locale, countryCode)
      .then((ribbons) => {
        dispatch(setRibbons({ ribbons, locale }));
      })
      .catch((err) => {
        err.message = `useRibbons: (${locale}) Couldn't load ribbons: ${err}`;
        Logger.error(err);
      });
  }, [countryCode, dispatch, locale]);

  return ribbon;
}

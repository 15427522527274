import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { getTrustpilotReviews, TrustPilotReview } from 'mycs/api/UserAPI';
import { PaginationButtons } from 'mycs/shared/components/Pagination/Pagination';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useTrustpilotScore } from 'mycs/hooks/useTrustpilotScore';
import Alink from '../Alink/Alink';
import CustomerReview from 'mycs/shared/components/ReviewsBlock/CustomerReview';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Icon from 'mycs/shared/components/Icon/Icon';
import StarRating from 'mycs/shared/components/ReviewsBlock/StarRating';

import styles from './CustomerReviewsBlock.scss';

// Format reviews number to german format (ex.: 1.234)
function formatReviewsNumber(locale: string, reviews: number): string {
  return new Intl.NumberFormat(locale.replace('_', '-')).format(reviews);
}

export default function CustomerReviewsBlock({
  hideDetails,
}: {
  hideDetails?: boolean;
}) {
  const { locale } = useLocale();
  const language = locale.split('_')[0];
  const href = `https://${language}.trustpilot.com/review/www.mycs.com`;
  const [page, setPage] = useState(1);
  const { companyScore } = useTrustpilotScore();
  const [customerReviews, setCustumerReviews] = useState<TrustPilotReview[]>(
    []
  );

  useEffect(() => {
    // Fetch product reviews summuries list from Trustpilot api
    getTrustpilotReviews(language).then((data) => {
      //ignore invalid data
      if (!data || !data.reviews || !Array.isArray(data.reviews)) {
        return;
      }

      setCustumerReviews(data.reviews);
    });
  }, [language, setCustumerReviews]);

  // Render customer reviews list
  const renderPages = (page: number) => {
    const reviewsPerPage = 3;
    const list = customerReviews?.slice(
      (page - 1) * reviewsPerPage,
      page * reviewsPerPage
    );

    return <div>{renderCustomerReviews(list)}</div>;
  };

  // Render customer reviews block
  const renderCustomerReviews = (list: typeof customerReviews) => {
    return list?.map((review) => {
      return <CustomerReview key={review.id} review={review} />;
    });
  };

  // Add paginatation component to render 3 reviews per page
  const renderPagination = () => {
    if (!customerReviews.length) return null;

    // Count total number of pages
    const totalPages = Math.ceil(customerReviews.length / 3);
    if (totalPages <= 1) return null;

    return (
      <PaginationButtons
        activePage={page}
        totalPages={totalPages}
        onPageChange={setPage}
        getPageLocation={() => undefined}
        getPageRel={() => undefined}
        isLoadMore={false}
      />
    );
  };

  if (customerReviews.length === 0) return null;

  return (
    <div
      className={classNames(styles.container, 'contentContainer')}
      id="customer-reviews"
    >
      <div className={styles.header}>
        <h3 className={styles.title}>
          {I18nUtils.localize(locale, 'Customer Reviews')}
        </h3>
        <div className={styles.scoreContainer}>
          <div className={styles.score}>
            {!hideDetails && (
              <div className={styles.scoreNumber}>
                {companyScore.trustScore}
              </div>
            )}
            <div className={styles.starsContainer}>
              {<StarRating stars={companyScore.stars} />}
            </div>
            <div className={styles.reviews}>
              <Alink href={href} target="_blank" rel="noreferrer">
                {formatReviewsNumber(locale, companyScore.reviews)}
                {' ' + I18nUtils.localize(locale, 'reviews')}
              </Alink>
            </div>
          </div>
          {!hideDetails && (
            <div className={styles.logoContainer}>
              <Icon
                className={styles.trustpilotLogo}
                iconName="customer-reviews/trustpilot_logo.svg"
                iconContainerClass={styles.trustpilotContainer}
              />
            </div>
          )}
        </div>
      </div>

      {renderPages(page)}
      {renderPagination()}
    </div>
  );
}

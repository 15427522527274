import { CfReviewsBlock } from '@mycs/contentful';
import CustomerReviewsBlock from './CustomerReviewsBlock';

export default function ReviewsBlock({ content }: { content: CfReviewsBlock }) {
  switch (content.type) {
    case 'Trust Pilot':
      return (
        <div>
          <CustomerReviewsBlock hideDetails={content.hideDetails} />
        </div>
      );

    default:
      return null;
  }
}

export function LegacyReviewsBlock({ hideDetails }: { hideDetails?: boolean }) {
  return (
    <ReviewsBlock
      content={{
        _id: 'legacyReviewsBlock',
        _contentType: 'interiorPage',
        type: 'Trust Pilot',
        hideDetails,
      }}
    />
  );
}

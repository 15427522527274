import classNames from 'classnames';
import Icon from 'mycs/shared/components/Icon/Icon';
import styles from './StarRating.scss';

interface StarRatingProps {
  stars: number;
  className?: string;
}

const sortedStarRanges = Object.entries({
  5: 4.8,
  4.5: 4.3,
  4: 3.8,
  3.5: 3.3,
  3: 2.8,
  2.5: 2.3,
  2: 1.8,
  1.5: 1.3,
  1: 0.8,
  0.5: 0.3,
  0: 0,
}).sort((a, b) => b[1] - a[1]);

function roundStars(stars: number) {
  for (const [key, value] of sortedStarRanges) {
    if (stars >= value) {
      return Number(key);
    }
  }

  return stars;
}

export default function StarRating(props: StarRatingProps) {
  const { stars, className } = props;

  if (!stars) return null;

  const roundedStars = roundStars(stars);
  const fullStars = Math.floor(roundedStars);
  const halfStars = roundedStars % 1 !== 0 ? 1 : 0;

  const starsArray = [];

  for (let i = 1; i <= 5; i++) {
    const isFullStar = i <= fullStars;
    const isHalfStar = i === fullStars + 1 && halfStars === 1;

    starsArray.push(
      <Icon
        key={i}
        iconName={
          isFullStar
            ? 'customer-reviews/star.svg'
            : isHalfStar
            ? 'customer-reviews/half_star.svg'
            : 'customer-reviews/empty_star.svg'
        }
        className={styles.starIcon}
      />
    );
  }

  return (
    <div className={classNames(styles.scoreStars, className)}>{starsArray}</div>
  );
}

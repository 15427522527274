import classNames from 'classnames';

import { useDevice } from 'mycs/router/DeviceContext';
import { CfNewsletterFormBanner } from '@mycs/contentful';
import NewsletterForm from 'mycs/shared/components/NewsletterForm/NewsletterForm';

import styles from './NewsletterFormBanner.scss';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { SignupSource } from 'mycs/api/UserAPI';
export default function NewsletterFormBanner({
  content: { title },
}: {
  content: CfNewsletterFormBanner;
}) {
  const device = useDevice();

  const buttonProps = {
    isBlackWhite: true,
  };

  return (
    <div className={classNames(styles.newsletter, 'contentContainer')}>
      <div className={styles.container}>
        <div className={styles.text}>{title}</div>

        <NewsletterForm
          containerClassName={styles.formContainer}
          formClassName={styles.form}
          inputClassName={styles.input}
          buttonClassName={styles.button}
          buttonProps={buttonProps}
          withPlaceholder
          errorMessageBottom={!device.hasPhoneDimensions}
          source={SignupSource.Banner}
        />
      </div>
    </div>
  );
}

export function LegacyNewsletterFormBanner({
  className,
}: {
  className?: string;
}) {
  const { locale } = useLocale();

  return (
    <div className={className}>
      <NewsletterFormBanner
        content={{
          _id: 'legacyNewsletterFormBanner',
          _contentType: 'serviceSpec',
          title: I18nUtils.localize(locale, 'Newsletter coupon'),
        }}
      />
    </div>
  );
}

import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { CfShowroomsList, CfShowroomUsps } from '@mycs/contentful';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import styles from './ShowroomUsps.scss';

//FIXME: should this be moved to mycs/cfg?
const showroomDisplayBlacklist = ['uk'];

type ShowroomUspsProps = {
  content: Omit<CfShowroomUsps, 'showroomList'> & {
    showroomList?: CfShowroomsList;
  };
};

export default function ShowroomUsps({
  content: {
    anchor,
    whiteLayout,
    showroomList,
  },
}: ShowroomUspsProps) {
  const { countryCode } = useLocale();
  const isShowroomHidden = showroomDisplayBlacklist.includes(countryCode);

  if (!showroomList || isShowroomHidden) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.whiteLayout]: whiteLayout,
      })}
      id={anchor}
    ></div>
  );
}

export function LegacyShowroomUsps({
  className,
  hideFooter,
  hideHeader,
  anchor,
  whiteLayout,
  hideTrustpilotWidget,
}: {
  className?: string;
  anchor?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  whiteLayout?: boolean;
  hideTrustpilotWidget?: boolean;
}) {
  const { locale, countryCode } = useLocale();
  const [showroomData, setShowroomData] = useState<CfShowroomsList | undefined>(
    undefined
  );

  function fetchShowrooms() {
    ContentfulService.getShowroomsList(locale, countryCode).then(
      setShowroomData
    );
  }

  useEffect(fetchShowrooms, [locale, countryCode]);

  return (
    <div className={className}>
      <ShowroomUsps
        content={{
          _id: 'legacyShowroomUsps',
          _contentType: 'serviceSpecsGroup',
          hideFooter,
          hideHeader,
          whiteLayout,
          anchor,
          showroomList: showroomData,
          hideTrustpilotWidget,
        }}
      />
    </div>
  );
}

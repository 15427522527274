import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';

import { CfCountdownRibbon, CfRibbon } from '@mycs/contentful';
import { useRibbon } from 'mycs/hooks/useRibbon';
import Countdown from 'mycs/shared/components/Countdown/Countdown';
import Icon from 'mycs/shared/components/Icon/Icon';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import Tooltip from '../Tooltip/Tooltip';

import styles from './CountdownRibbon.scss';

export default function CountdownRibbon({
  content,
}: {
  content?: CfCountdownRibbon;
}) {
  const [textIdx, setTextIdx] = useState(0);
  const ribbon = useRibbon();
  const [shouldRenderTimer, setShouldRenderTimer] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const bannerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setShouldRenderTimer(true);
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!ribbon) {
      return;
    }

    interval = runInterval(ribbon);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [ribbon]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        bannerRef.current &&
        !bannerRef.current.contains(event.target as Node)
      ) {
        setTooltipOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const shouldRender = !(
    !ribbon ||
    !ribbon.activated ||
    !ribbon.countdownTitles ||
    !ribbon.mobileContent
  );

  return (
    <Tooltip
      hint
      show={isTooltipOpen}
      content={ribbon?.mobileContent}
      placement="bottom"
    >
      <div
        ref={bannerRef}
        className={classNames(styles.container, getBackground(ribbon))}
        onClick={() => {
          setTooltipOpen((prev) => !prev);
        }}
      >
        {shouldRender && <>{renderHeader(ribbon, shouldRenderTimer)}</>}
      </div>
    </Tooltip>
  );

  function runInterval(ribbon: CfRibbon) {
    if (
      !ribbon ||
      !ribbon.countdownTitles ||
      ribbon.countdownTitles.length === 1
    )
      return null;

    const intervalTimer =
      ribbon.titlesAnimationSpeed || content?.animationSpeed || 2000;

    return setInterval(() => {
      if (!ribbon.countdownTitles) return;
      setTextIdx((textIdx) => (textIdx + 1) % ribbon.countdownTitles!.length);
    }, intervalTimer);
  }

  function getBackground(ribbon: CfRibbon | null) {
    const backgroundColours: { [key: string]: string } = {
      green: 'greenBackground',
      red: 'redBackground',
      lightRed: 'lightRedBackground',
      grey: 'greyBackground',
      yellow: 'yellowBackground',
      blue: 'blueBackground',
    };

    const styleBackground =
      (ribbon?.bannerBackgroundColour &&
        backgroundColours[ribbon?.bannerBackgroundColour]) ||
      (content?.backgroundColor &&
        backgroundColours[content?.backgroundColor]) ||
      backgroundColours.green;

    return styles[styleBackground];
  }

  function renderHeader(ribbon: CfRibbon, shouldRenderTimer: boolean) {
    const { countdownTitles, countdownTimer, hideCountdownClock } = ribbon;

    if (!countdownTitles) return null;
    const currentText = countdownTitles[textIdx];

    return (
      <div className={(styles.content, styles.headline)}>
        <SafeText className={styles.header} content={currentText} />

        <div
          className={classNames(styles.countdown, {
            [styles.hideCountdown]: hideCountdownClock,
          })}
        >
          {countdownTimer && (
            <>
              <Icon isInline iconName="general/info" />

              <Countdown
                endTime={countdownTimer}
                countdownRibbon
                showTimer={shouldRenderTimer}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

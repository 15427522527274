import { ReactNode } from 'react';
import classNames from 'classnames';

import Localize from 'mycs/shared/components/Localize/Localize';

import styles from './CountdownDisplay.scss';

type Props = {
  countdownRibbon: boolean;
  showTimer?: boolean;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export default function CountdownDisplay({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  countdownRibbon = false,
  showTimer = true,
}: Props) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.countdownRibbon]: countdownRibbon,
      })}
    >
      {days > 0 && renderField(days === 1 ? 'day' : 'days', days)}
      {(days > 0 || hours > 0) &&
        renderField(hours === 1 ? 'hour' : 'hours', hours)}
      {renderField('minutes', minutes)}
      {renderField('seconds', seconds)}
    </div>
  );

  function renderField(label: string, value: number): ReactNode {
    return (
      <div className={styles.field}>
        <span className={styles.fieldValue}>{showTimer ? value : '-'}</span>
        <span className={styles.fieldUnit}>
          <Localize>{label}</Localize>
        </span>
      </div>
    );
  }
}

import Icon from 'mycs/shared/components/Icon/Icon';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import styles from './ReviewReply.scss';

export default function ReviewReply({
  text,
  date,
  locale,
}: {
  text: string;
  date: string;
  locale: string;
}) {
  return (
    <div className={styles.replyContainer}>
      <Icon
        iconName="customer-reviews/reply_icon.svg"
        className={styles.replyIcon}
      />
      <div className={styles.titleContainer}>
        <h4 className={styles.replyTitle}>
          {I18nUtils.localize(locale, 'Reply from Mycs')}
        </h4>
        <span className={styles.replyDate}>{date}</span>
      </div>
      <p className={styles.replyText}>{text}</p>
    </div>
  );
}

import classNames from 'classnames';

import { CfSubcategory } from '@mycs/contentful';
import Alink from 'mycs/shared/components/Alink/Alink';
import CfImage from 'mycs/shared/components/CfImage/CfImage';
import Localize from 'mycs/shared/components/Localize/Localize';

import styles from './LinkedCategories.scss';

interface Props {
  categories: CfSubcategory[];
  title?: string;
  twoColumnsLayout?: boolean;
  id?: string;
  className?: string;
}

export default function LinkedCategories({
  id,
  className,
  categories,
  title,
  twoColumnsLayout = false,
}: Props) {
  if (!categories) return null;

  const imageSizes = '(max-width: 767px) 100vw, 50vw';

  const linkedCategories = categories.map((item, key) => (
    <div key={key} className={styles.linkedCategoryItem}>
      <Alink href={item.url} className={styles.link}>
        {item.image && <CfImage asset={item.image} sizes={imageSizes} />}
        <div className={styles.overlay} />
        <div className={styles.text}>
          <span>{item.title}</span>
        </div>
      </Alink>
    </div>
  ));

  return (
    <div className={classNames('contentContainer', className)} id={id}>
      {title ? (
        <h3 className={styles.title}>{title}</h3>
      ) : (
        <h3 className={styles.title}>
          <Localize>Related categories</Localize>
        </h3>
      )}

      <div
        className={classNames(styles.linkedCategories, {
          [styles.twoColumnsLayout]: twoColumnsLayout,
        })}
      >
        {linkedCategories}
      </div>
    </div>
  );
}

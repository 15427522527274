import { PureComponent, ReactNode } from 'react';
import CountdownDisplay from './CountdownDisplay';

type Props = {
  endTime: string;
  countdownRibbon: boolean;
  showTimer: boolean;
};

type State = {
  endTime: number;
  timeRemaining: number;
};

const SECOND = 1000; // milliseconds
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const initState = (propEndTime: string): State => {
  const endTime = new Date(propEndTime).valueOf();
  return {
    endTime,
    timeRemaining: endTime - Date.now(),
  };
};

export default class Countdown extends PureComponent<Props, State> {
  static defaultProps = {
    countdownRibbon: false,
    showTimer: true,
  };

  intervalHook = 0;

  state = initState(this.props.endTime);

  componentDidMount() {
    this.intervalHook = window.setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalHook);
  }

  tick = () => {
    const timeRemaining = this.state.endTime - Date.now();
    if (this.state.timeRemaining !== timeRemaining) {
      this.setState({ timeRemaining });
    }
  };

  static convert(timeDiff: number) {
    let remaining = timeDiff;
    const days = Math.floor(remaining / DAY);
    remaining -= days * DAY;
    const hours = Math.floor(remaining / HOUR);
    remaining -= hours * HOUR;
    const minutes = Math.floor(remaining / MINUTE);
    remaining -= minutes * MINUTE;
    const seconds = Math.floor(remaining / SECOND);

    return { days, hours, minutes, seconds };
  }

  render(): ReactNode {
    const { days, hours, minutes, seconds } = Countdown.convert(
      this.state.timeRemaining
    );

    return (
      <CountdownDisplay
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        countdownRibbon={this.props.countdownRibbon}
        showTimer={this.props.showTimer}
      />
    );
  }
}
